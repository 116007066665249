import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from './banner';
import Adverts from './adverts';

export default function Home() {

    return (
        <div>
            <Banner/>
            <Adverts/>
        </div>
    );
}
