import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
//import { Nav, Navbar } from 'react-bootstrap';
import About from './Screens/About';
import Footer from './Components/Js/Footer';
import Navbar from './Components/Js/Navbar';
import Home from './Components/Js/Home';
{/* <Route path="/" element={<div className="container"><h1 style={{ textAlign: 'center' }}>Hello, React!</h1><Footer /></div>} /> */}
class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Router>
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/about" element={<About />} />
                    </Routes>
            </Router>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));
