
import '../Css/adverts.css';
import AdvertsFilter from './adverts-filter';
import Advert from './advert';

export default function Adverts() {
    return (
        <div className='container-adverts'>
            <div className='row'>
                <div className='col'>
                    <AdvertsFilter />
                </div>
                <div className='col-8'>
                    <Advert />
                </div>
            </div>
        </div>
    )
}
