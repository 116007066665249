import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importe o arquivo CSS do Bootstrap, se ainda não estiver fazendo isso
import '../Css/banner.css'; // Importe seu arquivo de estilos personalizados
import image1 from '../Images/banner.svg';
import image2 from '../Images/banner.svg';
import image3 from '../Images/banner.svg';

export default function Banner() {
    return (
        <Carousel className='container-banner'>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={image1}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={image2}
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={image3}
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
    );
}
