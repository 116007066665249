import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../Css/navbar-custom.css';

import mypic from '../Images/logo-navbar.svg';

export default function Navbar() {
    const navigate = useNavigate();

    useEffect(() => {
        if (document) {
            var links = document.querySelectorAll('.nav-link');
            var marks = document.querySelectorAll('.mark');
            var mark = '';

            if (links.length) {
                links.forEach((link) => {
                    link.addEventListener('click', (e) => {
                        links.forEach((link) => {
                            link.classList.remove('active');
                        });
                        marks.forEach((mark) => {
                            mark.classList.remove('nav-marker');
                        });
                        e.preventDefault();
                        link.classList.add('active');

                        if (e.target.id === 'howwork') {
                            mark = document.getElementById('markerh');
                        } else if (e.target.id === 'adds') {
                            mark = document.getElementById('markera');
                        } else if (e.target.id === 'subscribe') {
                            mark = document.getElementById('markers');
                        } else {
                            mark = document.getElementById('markerl');
                        }
                        mark.classList.add('nav-marker');
                    });
                });
            }
        }
    }, []);

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light ">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={mypic} alt="Sua Marca" width="120" height="60" className="d-inline-block align-top" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <ul className="nav navbar-nav">
                        <li className="nav-item px-4">
                            <a id="howwork" className="nav-link" href="#">
                                Como funciona?
                            </a>
                            <div id="markerh" className="mark"></div>
                        </li>

                        <li className="nav-item px-4 adds-li">
                            <a
                                id="adds"
                                onClick={() => handleNavigate("/adverts")}
                                className="nav-link active"
                            >
                                Anuncios
                            </a>
                            <div id="markera" className="mark nav-marker"></div>
                        </li>
                    </ul>
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item px-4">
                            <a id="subscribe" className="nav-link" href="#">
                                Registrar
                            </a>
                            <div id="markers" className="mark"></div>
                        </li>
                        <li className="nav-item px-4">
                            <a id="login" className="nav-link" href="#">
                                Entrar
                            </a>
                            <div id="markerl" className="mark"></div>
                        </li>

                        <li className="nav-item px-4">
                            <button className="btn navbar-btn button-navbar" type="submit">
                                <a className="button-text">POSTAR ANUNCIO</a>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
